<template>
  <div class="c-table-responsive border pb-0.5 md:pb-0 border-primary-grey rounded-md">
    <table class="c-table cursor-pointer flex flex-col">
      <thead class="flex">
        <th
          v-for="weekDay in weekDays"
          :key="weekDay"
          class="flex-1 font-roboto font-medium text-base text-text-color py-2 px-0 border-primary-grey"
        >
          <div class="ltr:-ml-px border-primary-grey ltr:border-l rtl:border-r">
            <span v-if="bodyDir">
              {{ $t(`attendanceTranslation.${weekDay}`) }}
            </span>
            <span v-else>
              {{ weekDay.slice(0, 3) }}
            </span>
          </div>
        </th>
      </thead>
      <tbody class="flex flex-col mb-0.5 md:mb-0 mt-4">
        <tr
          v-for="(week, wIndex) in calendarDaysForCurrentMonth"
          :key="wIndex"
          class="flex ltr:-ml-px"
        >
          <td
            v-for="(day, dIndex) in week"
            :key="dIndex"
            class="flex-1 -mt-px ltr:border-l rtl:border-r border-b md:border-b-0 border-t border-primary-grey text-label-text h-32 p-1 2xl:p-2"
            :class="day.isCurrentMonth && isHighlighted(getDay(day, wIndex, dIndex))"
            @mouseEnter="setActiveClass(getDay(day, wIndex, dIndex), day.isCurrentMonth)"
            @click="selectDay(day.isCurrentMonth, getDay(day, wIndex, dIndex))"
          >
            <div class="single-date" :class="activeDayClass(day, wIndex, dIndex)">
              <span>{{ getTranslatedDay(getDay(day, wIndex, dIndex)) }}</span>
            </div>
            <div v-if="day.isCurrentMonth" :key="getDay(day, wIndex, dIndex)" class="slot-content">
              <slot :events="getEvents(getDay(day, wIndex, dIndex))" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatDate } from '@utils/moment.util'
import TIME_CONSTANTS from '@src/constants/date-time-constants'

export default {
  name: 'Calendar',
  props: {
    selectDayRange: {
      type: Boolean,
      default: false,
    },
    calendarDate: {
      type: [String, Date],
      default: null,
    },
    events: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['clickDay'],
  data() {
    return {
      currentDate: null,
      calendarDaysForCurrentMonth: null,

      startDiff: null,

      firstDayOfCurrentMonth: null,
      totalDaysInCurrentMonth: null,

      rangeSelect: [],

      tempEndDay: null,
      bodyDir: document.querySelector('body').dir === 'rtl',
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    }
  },
  watch: {
    calendarDate: {
      deep: true,
      immediate: true,
      handler() {
        if (this.calendarDate) {
          this.currentDate = this.calendarDate
        }
      },
    },
  },

  mounted() {
    if (this.calendarDate) {
      this.currentDate = new Date(this.calendarDate)
    } else {
      this.currentDate = new Date()
    }
    this.firstDayOfCurrentMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      1,
    )

    this.totalDaysInCurrentMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() + 1,
      0,
    ).getDate()

    this.startDiff =
      this.firstDayOfCurrentMonth.getDay() === 0 ? 5 : this.firstDayOfCurrentMonth.getDay() - 2

    this.fillMonth(
      this.firstDayOfCurrentMonth.getDay() === 0 ? 6 : this.firstDayOfCurrentMonth.getDay() - 1,
      this.totalDaysInCurrentMonth,
      this.currentDate,
    )
  },
  methods: {
    getEvents(day) {
      return this.events.filter(
        (event) =>
          +formatDate(`${event.date}T${event.startTime}Z`, TIME_CONSTANTS.DAY_FROM_DATE) === day,
      )
    },
    isHighlighted(day) {
      function isBetween(n, a, b) {
        return (n - a) * (n - b) <= 0
      }

      if (this.rangeSelect[0] === day || this.rangeSelect[1] === day) {
        return 'c-highlight'
      } else if (this.rangeSelect.length === 2) {
        if (isBetween(day, this.rangeSelect[0], this.rangeSelect[1])) {
          return 'c-highlight'
        }
      } else if (this.rangeSelect.length === 1) {
        if (isBetween(day, this.rangeSelect[0], this.tempEndDay)) {
          return 'c-highlight'
        }
      }
    },

    selectDay(isCurrentMonth, day) {
      if (isCurrentMonth) {
        if (this.selectDayRange) {
          this.rangeSelector(day)
        } else {
          this.$emit('clickDay', day)
        }
      }
    },

    rangeSelector(day) {
      if (this.rangeSelect.length === 0 || this.rangeSelect.length === 2) {
        this.rangeSelect = []

        this.rangeSelect.push(day)
      } else {
        this.rangeSelect.push(day)
      }
    },

    setActiveClass(day) {
      this.tempEndDay = day
    },

    getDay(day, wIndex, dIndex) {
      return day.dayNumber
        ? day.dayNumber
        : day.isCurrentMonth
        ? 7 * wIndex + (dIndex - this.startDiff)
        : 7 * wIndex + (dIndex - this.startDiff) - this.totalDaysInCurrentMonth
    },

    activeDayClass(day, wIndex, dIndex) {
      const dayClass = this.getDay(day, wIndex, dIndex)
      const currentMonth = new Date()
      if (day.isCurrentMonth) {
        if (
          currentMonth.getDate() === dayClass &&
          this.currentDate.getFullYear() === currentMonth.getFullYear() &&
          this.currentDate.getMonth() === currentMonth.getMonth()
        ) {
          return 'current-active-day'
        }
      }
    },

    fillMonth(startDayOfMonth, totalDaysInMonth) {
      const daysOfMonth = new Array(42)

      daysOfMonth.fill({
        isCurrentMonth: true,
      })

      const remaingSlotsInMonth = 42 - (startDayOfMonth + totalDaysInMonth)
      let prevMonthDays = null
      if (startDayOfMonth > 0) {
        prevMonthDays = new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth(),
          0,
        ).getDate()
      }

      for (let i = 0; i < startDayOfMonth; i++) {
        daysOfMonth[i] = {
          dayNumber: prevMonthDays - (startDayOfMonth - 1 - i),
          isCurrentMonth: false,
        }
      }

      for (let i = 1; i <= remaingSlotsInMonth; i++) {
        daysOfMonth[daysOfMonth.length - i] = {
          isCurrentMonth: false,
        }
      }

      const daysOfMonthChunk = []

      while (daysOfMonth.length > 0) {
        const splicedChunk = daysOfMonth.splice(0, 7)

        if (!(daysOfMonthChunk.length > 0 && !splicedChunk[0].isCurrentMonth)) {
          daysOfMonthChunk.push(splicedChunk)
        }
      }

      this.calendarDaysForCurrentMonth = daysOfMonthChunk
    },

    getTranslatedDay(presentDate) {
      var trasnlatedDate = presentDate
        .toString()
        .split('')
        .map((translateNum) => this.$t(`rightBar.${translateNum}`))
        .join('')

      return `${trasnlatedDate}`
    },
  },
}
</script>

<style lang="scss" scoped>
.c-table {
  overflow: auto;
  border-spacing: 0;
  border-collapse: collapse;
}

th,
td {
  min-width: 90px;
  font-size: 0.875em;
  text-align: center;
  vertical-align: top;
  @media only screen and (max-width: 450px) {
    min-width: 70px;
    font-size: 0.81em;
  }
}

.slot-content {
  margin-top: 10px;
}

.c-highlight {
  background-color: #eee;
}

.current-active-day {
  display: inline-block;
  padding: 5px 10px;
  color: var(--primary-purple-600);
  background-color: var(--primary-purple-100);
  border-radius: 3px;
}

.single-date {
  display: inline-block;
  padding: 5px 10px;
}

body[dir='rtl'] {
  table thead th:first-child div {
    border-right-width: 0;
  }
  table tbody tr td.border-t:first-child {
    border-right-width: 0;
  }
}
</style>
