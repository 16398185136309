export default {
  methods: {
    localTime(time, onlyTime) {
      var timeString = time
      var H = +timeString.substr(0, 2)
      var h = H % 12 || 12
      var ampm = !onlyTime ? (H < 12 || H === 24 ? ' AM' : ' PM') : ''
      timeString = `${h}`.padStart(2, '0') + timeString.substr(2, 3) + ampm
      return timeString
    },
    Dateformat(date) {
      var formatdate = new Date(date)

      return new Intl.DateTimeFormat(['en-ca', 'ar-EG']).format(formatdate)
    },
    getDay(date) {
      return new Date(date).getDate()
    },
  },
}
