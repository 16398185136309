import GENERAL_CONSTANTS from '@src/constants/general-constants'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'

export const rightBarStats = (
  total_students,
  total_records,
  total_checked_in,
  total_checked_out,
  unique_checked_in,
  unique_checked_out,
) => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
      categories: [
        {
          text: 'TOTAL_STU',
          value: total_students || GENERAL_CONSTANTS.EMPTY_RECORD,
          roles: [
            TENANT_ROLES.SUPER_ADMIN,
            TENANT_ROLES.SECTION_TEACHER,
            TENANT_ROLES.CAMPUS_ADMIN,
          ],
        },
        {
          text: 'TOTAL_ATT',
          value: total_records || GENERAL_CONSTANTS.EMPTY_RECORD,
          roles: [
            TENANT_ROLES.SUPER_ADMIN,
            TENANT_ROLES.SECTION_TEACHER,
            TENANT_ROLES.CAMPUS_ADMIN,
          ],
        },
        {
          text: 'ATT_IN',
          value: total_checked_in || GENERAL_CONSTANTS.EMPTY_RECORD,
        },
        {
          text: 'ATT_OUT',
          value: total_checked_out || GENERAL_CONSTANTS.EMPTY_RECORD,
        },
        {
          text: 'U_ATT_IN',
          value: unique_checked_in || GENERAL_CONSTANTS.EMPTY_RECORD,
          roles: [
            TENANT_ROLES.SUPER_ADMIN,
            TENANT_ROLES.SECTION_TEACHER,
            TENANT_ROLES.CAMPUS_ADMIN,
          ],
        },
        {
          text: 'U_ATT_OUT',
          value: unique_checked_out || GENERAL_CONSTANTS.EMPTY_RECORD,
          roles: [
            TENANT_ROLES.SUPER_ADMIN,
            TENANT_ROLES.SECTION_TEACHER,
            TENANT_ROLES.CAMPUS_ADMIN,
          ],
        },
      ],
    },
  ]
  return stats
}
export const ATTENDANCE_STATUSES = [
  { title: 'Present', value: 'present' },
  { title: 'Absent', value: 'absent' },
]
export const CHECKED_TYPE = [
  { title: '	Attendance In', value: 'checked_in' },
  { title: '	Attendance Out', value: 'checked_out' },
]
export const rightBarStatus = () => {
  const status = [
    {
      title: 'Status',
      attendancePercentageMetaData: [
        {
          color: '#267C26',
          text: 'Checked in on time 80%',
        },
        {
          color: '#FFC201',
          text: 'Checked in on time 50-80%',
        },
        {
          color: '#EA3535',
          text: 'Checked in on time 50%',
        },
      ],
      subjectsMetaData: [
        {
          color: '#267C26',
          text: this.$t('rightBar.Checked In'),
        },
        {
          color: '#FFC201',
          text: this.$t('rightBar.Checked Out'),
        },
      ],
    },
  ]
  return status
}
export const INITIAL_START_TIME = '09:00 am'
export const INITIAL_END_TIME = '01:00 pm'
export const ATTENDANCE_GOOD = 80
export const ATTENDANCE_OK = 50
export const CHECK_IN = 'checkIn'
export const CHECKED_IN = 'checked_in'
export const CHECKED_OUT = 'checked_out'
export const WEEKLY_VIEW_TABLE_HEADS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]
export const FILTER_OPTIONS = ['Month', 'Week', 'Day']

export const attendanceColorCodes = {
  PRESENT: '#7f56d9',
  ABSENT: '#d92d20',
  DELAY: '#F79009',
}
