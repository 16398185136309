import { convertUTCTimeIntoLocalTime } from '@/src/utils/generalUtil'
export const getStudentAttendanceWeek = (records) => {
  const attendances = []
  Object.keys(records).forEach((date) => {
    records[date].forEach((record) => {
      let date = record.checked_at
      date = new Date(date)
      let endTime = date.setMinutes(date.getMinutes() + 60)
      endTime = new Date(endTime)
      const endTimeFormat =
        endTime.getHours().toString().padStart(2, '0') +
        ':' +
        endTime.getMinutes().toString().padStart(2, '0') +
        ':' +
        endTime.getSeconds().toString().padStart(2, '0')
      const event = getSubjectEvent(record, endTimeFormat)

      attendances.push(event)
    })
  })

  records = attendances
  const allEvents = records.reduce((acc, record) => {
    const event = {
      ...record,
      parentId: null,
      overlappedIds: [],
    }

    event.overlappedIds = findCollappsingForRecords(record, records)

    const isExist = []

    event.overlappedIds.forEach((id) => {
      if (acc[id]) {
        isExist.push(id)
      }
    })

    // acc = [...acc, event];

    if (isExist.length === 0) {
      acc[event.id] = { ...event }
    } else {
      if (isExist.length !== 1) {
        const allOverlapped = isExist.reduce((ac, val) => {
          acc[val].parentId = event.id
          ac = [...ac, ...acc[val].overlappedIds]

          return ac
        }, [])

        event.overlappedIds = [...event.overlappedIds, ...allOverlapped]
      } else {
        if (!acc[isExist[0]].parentId) {
          acc[isExist[0]].overlappedIds = [...acc[isExist[0]].overlappedIds, ...event.overlappedIds]

          event.parentId = isExist[0]
        } else {
          acc[acc[isExist[0]].parentId].overlappedIds = [
            ...acc[acc[isExist[0]].parentId].overlappedIds,
            ...event.overlappedIds,
          ]

          event.parentId = acc[isExist[0]].parentId
        }
      }

      acc[event.id] = { ...event }
    }

    return acc
  }, {})
  return Object.values(allEvents)
    .filter((e) => !e.parentId)
    .map((e) => {
      return {
        ...e,
        overlappedIds: e.overlappedIds
          .filter((id) => id !== e.id)
          .reduce((ac, val) => {
            if (!ac.includes(val)) {
              ac.push(val)
            }
            return ac
          }, []),
      }
    })
    .map((record) => {
      return getSubjectEventUtc(record)
    })
}

const findCollappsingForRecords = (current, all) => {
  return all
    .filter((record) => {
      if (record.id === current.id) {
        return false
      } else {
        return timeCollapsed(current, record)
      }
    })
    .map((r) => r.id)
}

const timeCollapsed = (current, record) => {
  if (current.day !== record.day) {
    return false
  } else if (
    timeComparison(current.startTime, record.startTime) === 1 &&
    timeComparison(current.endTime, record.startTime) === 2 &&
    timeComparison(current.endTime, record.endTime) === 1
  ) {
    return true
  } else if (
    timeComparison(current.startTime, record.startTime) === 2 &&
    timeComparison(current.endTime, record.endTime) === 1
  ) {
    return true
  } else if (
    timeComparison(current.startTime, record.startTime) === 1 &&
    timeComparison(current.endTime, record.endTime) === 2
  ) {
    return true
  } else if (
    timeComparison(current.startTime, record.startTime) === 3 ||
    timeComparison(current.endTime, record.endTime) === 3
  ) {
    return true
  } else {
    return false
  }
}

const timeComparison = (timeOne, timeTwo) => {
  const timeOneSplit = timeOne
  // new Date(timeOne)
  //   .toISOString()
  //   .split('T')[1]
  //   .split('.')[0]
  //   .split(':')
  const timeTwoSplit = timeTwo
  // new Date(timeTwo)
  //   .toISOString()
  //   .split('T')[1]
  //   .split('.')[0]
  //   .split(':')

  if (timeOneSplit[0] < timeTwoSplit[0]) {
    return 1
  } else if (timeOneSplit[0] > timeTwoSplit[0]) {
    return 2
  } else {
    if (timeOneSplit[1] < timeTwoSplit[1]) {
      return 1
    } else if (timeOneSplit[1] > timeTwoSplit[1]) {
      return 2
    } else {
      if (timeOneSplit[2] < timeTwoSplit[2]) {
        return 1
      } else if (timeOneSplit[2] > timeTwoSplit[2]) {
        return 2
      } else {
        return 3
      }
    }
  }
}

export const getSubjectEvent = (record, endTime) => {
  var weekday = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  const day = weekday[new Date(record.checked_at.split('T')[0]).getDay()]
  const event = {
    id: record.bulk_check_id,
    checked_by_fname: record.checked_by_fname,
    checked_by_lname: record.checked_by_lname,
    check_count: record.check_count,
    isOverlapped: false,
    title: record.checked_type,
    day: day,
    details: true,
    date: record.checked_at.split('T')[0],
    startTime: convertUTCTimeIntoLocalTime(record.checked_at.split('T')[1]),
    endTime: endTime,
    color: record.checked_type === 'checked_in' ? '#267C26' : '#921A1D',
    presentColor: '#267C26',
  }

  return event
}

export const getSubjectEventUtc = (payload) => {
  const event = {
    id: payload.id,
    checked_by_fname: payload.checked_by_fname,
    checked_by_lname: payload.checked_by_lname,
    check_count: payload.check_count,
    isOverlapped: payload.overlappedIds?.length > 0,
    overlappedIds: payload.overlappedIds,
    title: payload.title,
    day: payload.day,
    details: true,
    date:
      new Date(payload.date).getFullYear() +
      '-' +
      (new Date(payload.date).getMonth() + 1) +
      '-' +
      new Date(payload.date).getDate(),
    startTime: payload.startTime,
    endTime: payload.endTime,
    color: payload.color,
  }

  return event
}
