<template>
  <div>
    <div
      class="px-3 pt-3 border border-white rounded-lg cursor-pointer"
      :style="getStyle(event)"
      @click="$emit('eventClicked')"
    >
      <div v-if="!hideOverlap" class="mask mask--opacity">
        <RedMask />
      </div>
      <slot :event="event"></slot>
      <div v-show="!hideActions && (actionsActive || actionsDropdown)">
        <div
          class="absolute top-3 ltr:left-auto ltr:right-3 rtl:left-0 rtl:right-auto pl-3 pr-3 -mr-3"
          @click="actionsPopUp"
        >
          <icon class="cursor-pointer" height="14px" width="4px" icon="ellipsis" color="white" />
        </div>
        <UiOnClickOutside :do="outsideClicked">
          <div
            v-show="actionsDropdown"
            class="absolute top-5 right-1-5 bg-white rounded border-1 border-primary-gray"
          >
            <slot name="actions" :event="event"></slot>
          </div>
        </UiOnClickOutside>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import RedMask from '@assets/images/masks/red_mask.vue'
import icon from '@components/icons/icon.vue'
export default {
  name: 'Event',
  components: {
    UiOnClickOutside,
    RedMask,
    icon,
  },
  props: {
    isCheckIn: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: () => {},
    },
    // It on the single day of that event
    dayEvent: {
      type: Boolean,
      default: false,
    },
    tableTime: {
      type: [Object, Array],
      required: true,
    },
    tableHead: {
      type: [Object, Array],
      required: true,
    },
    hideOverlap: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['eventClicked'],
  data() {
    return {
      actionsActive: true,
      actionsDropdown: false,
    }
  },
  methods: {
    outsideClicked(e) {
      if (this.actionsDropdown) {
        this.actionsPopUp()
      }
    },
    actionsPopUp(e, status = null) {
      e.stopPropagation()
      this.actionsDropdown = status === null ? !this.actionsDropdown : status
    },
    getStyle(eventObj) {
      const heightScale = 114
      const widthScale = 114
      const tableStartMoment = moment(this.tableTime[0], 'hh:mm a')

      const diff = moment
        .utc(moment(eventObj.endTime, 'HH:mm a').diff(moment(eventObj.startTime, 'HH:mm a')))
        .format('HH:mm:ss')
      // console.log('difference', diff, eventObj.startTime)

      const [hour, minute] = diff.split(':')

      const timeDuration = {}

      timeDuration.hour = hour
      timeDuration.minute = minute
      let heightEvent = 0
      let widthEvent = widthScale

      if (timeDuration.hour > 0) {
        heightEvent = timeDuration.hour * heightScale
      }

      if (timeDuration.minute > 0) {
        heightEvent += (timeDuration.minute * heightScale) / 60
      }

      let positionTop = 0

      const startTime = moment(eventObj.startTime, 'HH:mm:ss')

      if (tableStartMoment.format('a') === startTime.format('a')) {
        if (+tableStartMoment.format('hh') === +startTime.format('hh')) {
          positionTop = 0
        } else {
          positionTop += +startTime.format('HH') - +tableStartMoment.format('HH')
          positionTop = Math.abs(positionTop)
        }
      } else {
        positionTop += +startTime.format('HH') - +tableStartMoment.format('HH')
      }
      let positionLeft = 90

      const headIdx = this.tableHead.indexOf(eventObj.day)

      positionTop = positionTop * heightScale

      if (startTime.format('mm') > 0) {
        positionTop += (startTime.format('mm') * heightScale) / 60
      }

      if (!this.dayEvent) {
        positionLeft = positionLeft + headIdx * widthScale
      }
      widthEvent = this.dayEvent ? `calc(100% - 100px)` : `${widthEvent - 4}px`
      if (document.querySelector('body').dir === 'rtl')
        return {
          backgroundColor: eventObj.color,
          height: `${heightEvent - 2}px`,
          width: `${widthEvent}`,
          position: 'absolute',
          top: `${+positionTop + 1}px`,
          right: `${+positionLeft + 2}px`,
          color: `white`,
        }
      else
        return {
          backgroundColor: eventObj.color,
          height: `${heightEvent - 2}px`,
          width: `${widthEvent}`,
          position: 'absolute',
          top: `${+positionTop + 1}px`,
          left: `${+positionLeft + 2}px`,
          color: `white`,
          overflow: 'hidden',
        }
    },
  },
}
</script>

<style lang="scss" scoped>
.right-1-5 {
  right: 0.5em;
}
.top-3 {
  top: 0.75em;
}

.right-3 {
  right: 0.75em;
}

.top-5 {
  top: 2.25em;
}

.right-5 {
  right: 1.75em;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: -120px;
  opacity: 0.5;
}
</style>

<style lang="scss">
.mask--opacity {
  svg {
    path {
      opacity: 0.5;
    }
  }
}
</style>
