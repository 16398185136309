<template>
  <div>
    <ValidationObserver @submit="formCheck">
      <UiModalContainer
        footer-classes="flex justify-end"
        :modal-show="modal"
        get-student-attendances
        :modal-width="50"
        @handleClick="handleClick"
      >
        <template v-slot:header>
          <span v-i18n="attendanceTranslation">MARK_SECTION_ATTENDANCE</span>
        </template>
        <template v-slot>
          <div class="main-content">
            <div>
              <div class="bg-primary-white border-b border-primary-purple-100 pb-1">
                <InputFieldWrapper>
                  <UiSingleSelect
                    v-if="!currentSectionScope"
                    v-model="selectedSection"
                    title="Section"
                    :options="sectionList"
                    label="title"
                    reduce="id"
                    rules="required"
                    class="flex-1"
                    @change="loadStudentList(selectedSection)"
                  />
                </InputFieldWrapper>
                <InputFieldWrapper>
                  <UiDateTimePicker
                    v-model="checked.checked_at"
                    title="Maked at"
                    class="flex-1"
                    name="Maked at"
                    rules="required"
                  />
                </InputFieldWrapper>

                <InputFieldWrapper>
                  <UiInput
                    v-model="checked.comment"
                    type="text"
                    name="Attendance Title"
                    title="Attendance Title"
                    label="Attendance Title"
                    placeholder="Attendance Title"
                    class="flex-1"
                    rules="required"
                  />
                </InputFieldWrapper>
              </div>
              <div v-if="isLoading" class="mt-20 mb-48">
                <Loader :content="true" />
              </div>
              <div v-else-if="!isLoading && isEmpty(studentList)" class="my-10">
                <NoRecordFound />
              </div>
              <div v-else>
                <div class="font-medium text-xl text-label-text my-5">Students</div>
                <TableWrapper id="section-attendance-table">
                  <THead>
                    <TRHead>
                      <TH>
                        <span v-i18n="attendanceTranslation">Student Name</span>
                      </TH>
                      <TH>
                        <span class="flex items-center gap-2.5">
                          <UiCheckbox
                            :model-value="AllChecked"
                            :no-error="true"
                            @change="setAllChecked(!AllChecked)"
                          />
                          <span v-i18n="attendanceTranslation">SBCO_TH3</span>
                        </span>
                      </TH>
                      <TH>
                        <span v-i18n="attendanceTranslation">COMMENT</span>
                      </TH>
                    </TRHead>
                  </THead>
                  <TBody>
                    <TRBody v-for="(student, index) in currentList" :key="student.id + forceUpdate">
                      <TD>
                        <SingleUserDisplay :user="student" label="full_name" :image="true" />
                      </TD>
                      <TD>
                        <span class="text-primary-purple-600">
                          <UiCheckbox
                            type="checkbox"
                            :no-error="true"
                            :name="`check-${index}`"
                            :model-value="student.status"
                            @change="updateStatus(index, $event)"
                          />
                        </span>
                      </TD>
                      <TD>
                        <InputBox
                          v-model="student.comment"
                          type="text"
                          title="Comment"
                          :name="`Comment ${index} ${student.id}`"
                          :hide-title="true"
                          placeholder="Comment"
                          :no-error="true"
                        />
                      </TD>
                    </TRBody>
                  </TBody>
                </TableWrapper>
              </div>
              <Pagination
                v-if="studentList.length > RECORD_LIMIT"
                :record-limit="RECORD_LIMIT"
                :max-range="paginationCounts(studentList.length, RECORD_LIMIT)"
                @filterRecord="filterRecord"
              />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="w-full -mt-4">
            <div
              v-if="studentList.length > 0"
              v-i18n="Footer"
              class="xl:text-lg sm:text-base text-sm text-primary-purple-600"
            >
              ATTENDANCE_NOTE
            </div>
            <div class="flex gap-2.5 mt-4 justify-end">
              <UIButton @click="handleClick('close')">Cancel</UIButton>
              <UIButton
                button="primary"
                :disabled="disableButton"
                :class="disableButton ? 'disabled-btn' : ''"
              >
                <span v-if="isApiLoading"><Loader /></span>
                <span v-else>Submit</span>
              </UIButton>
            </div>
          </div>
        </template>
      </UiModalContainer>
    </ValidationObserver>
  </div>
</template>

<script>
import UiCheckbox from '@components/UiElements/UiCheckbox.vue'
import UiDateTimePicker from '@src/components/UiElements/UIDateTimePicker.vue'
import { mapState, mapActions } from 'vuex'
import UiInput from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'

import { utcOffsetZero } from '@src/utils/format-date.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
/* MIXINS */
import generalMixin from '@src/mixins/general-mixins.js'
/* CONSTANTS */
import GENERAL from '@src/constants/general-constants'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import isEmpty from 'lodash/isEmpty'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { removeEmptyKeysFromObject } from '@src/utils/generalUtil.js'
import { Form as ValidationObserver } from 'vee-validate'
import InputBox from '@components/UiElements/UiInputBox.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    UiDateTimePicker,
    UiCheckbox,
    ValidationObserver,
    UiInput,
    Loader,
    SingleUserDisplay,
    Pagination,
    UiModalContainer,
    NoRecordFound,
    UiSingleSelect,
    InputBox,
    UIButton,
    InputFieldWrapper,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalMixin],
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  page: {
    title: 'Attendance | Section Attendance',
    meta: [
      {
        name: 'description',
        content: 'Section Attendance  of each subject class ',
      },
    ],
  },
  emits: ['toggle', 'fetch'],
  data() {
    return {
      GENERAL,
      Footer: 'Footer',
      options: ['Select'],
      isLoading: false,
      isApiLoading: false,
      studentList: '',
      checked: {
        time: '',
        date: new Date(),
        student: '',
        comment: '',
      },
      AllChecked: false,
      RECORD_LIMIT: GENERAL.RECORD_LIMIT,
      arrayStart: 0,
      arrayEnd: GENERAL.RECORD_LIMIT,
      attendanceTranslation: 'attendanceTranslation',
      forceUpdate: 1,
      disableButton: true,
      selectedSection: null,
    }
  },
  computed: {
    ...mapState({
      submitForm: (state) => state.layout.submitForm,
      BulkCheckSubmit: (state) => state.layout.BulkCheckSubmit,
      sectionList: (state) => state.section.sectionList,
      currentSectionScope: (state) => state.layout.currentSectionScope?.id,
    }),
    /**
     * CURRENT ACTIVE LIST
     */
    currentList() {
      return this.studentList.slice(this.arrayStart, this.arrayEnd)
    },
  },
  watch: {
    /**
     * UPDATING ALL CHECKED WHENEVER ARRAY LIST CHANGES
     */
    currentList: {
      handler() {
        this.allCheckForFilterRecord()
      },
    },
  },
  mounted() {
    if (this.currentSectionScope) this.loadStudentList()
    else this.filterSectionsList()
  },
  methods: {
    utcOffsetZero,
    isEmpty,
    /**
     * FOR PAGINATION
     */
    filterRecord(range = { skip: GENERAL.RECORD_SKIP, limit: GENERAL.RECORD_LIMIT }) {
      this.arrayStart = range.skip
      this.arrayEnd = range.skip + range.limit
      let totalRequestedCount = range.skip + range.limit
      if (this.studentList.length && totalRequestedCount >= this.studentList.length) {
        this.disableButton = false
      }
    },

    allCheckForFilterRecord() {
      if (Array.isArray(this.currentList)) {
        let bool = !this.currentList.some((student) => {
          return student.status !== true
        })
        let absentCount = 0
        this.studentList.forEach((student) => {
          if (student.status !== true) {
            absentCount++
          }
        })

        this.AllChecked = bool
      }
    },
    setAllChecked(status) {
      this.currentList.map((student) => {
        student.status = status
      })
      this.allCheckForFilterRecord()
      this.forceUpdate = this.forceUpdate + 2
    },
    updateStatus(index, e) {
      this.currentList[index].status = e.status
      this.allCheckForFilterRecord()
    },
    updateComment(index, e) {
      this.currentList[index].comment = e.target.value
    },
    async formCheck() {
      try {
        this.disableButton = true
        this.checked.student = this.studentList.map((student) => ({
          user_id: student.id,
          status: student.status ? 'present' : 'absent',
          comment: student.comment ? student.comment : '',
        }))
        const data = {
          user_type: 'section_student',
          marked_at: this.checked.checked_at,
          user_section_attendances: this.checked.student,
          title: this.checked.comment,
        }
        if (this.selectedSection) data['section_id'] = this.selectedSection

        this.isApiLoading = true

        await this.markStudentCheckInOut(data).then(() => {
          this.isLoading = false
          this.setBulkCheckSubmit()
          this.$emit('toggle', 'fetch')
        })
      } catch (err) {
        this.isApiLoading = false
      }
    },

    async loadStudentList(sectionId) {
      try {
        this.isLoading = true
        let payload = {
          section_id: sectionId,
        }
        removeEmptyKeysFromObject(payload)
        const [result, error] = await this.getSubjectExamStudents(payload)
        result.data.forEach((student) => {
          student.full_name = `${student.first_name} ${student.last_name}`
          student.status = false
        })
        this.isLoading = false
        this.studentList = result.data
        this.allCheckForFilterRecord()
        this.filterRecord()
      } catch (err) {
        this.isLoading = false
      }
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.setBulkCheckSubmit()
          this.$emit('toggle')
          break
        case 'cancel':
          break
      }
    },
    ...mapActions('layout', ['setLeftbarContent', 'setBulkCheckSubmit']),
    ...mapActions('student', ['loadStudentCheckInAndOut', 'markStudentCheckInOut']),
    ...mapActions('exams', ['getSubjectExamStudents']),
    ...mapActions('section', ['filterSectionsList']),
  },
}
</script>

<style lang="scss" scoped>
.disabled-btn {
  background-color: var(--primary-grey);
  border: none;
}
</style>
