<template>
  <div>
    <SectionAttendanceFilters
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <div>
      <div class="items-center justify-center">
        <CalendarActionsWrapper
          :calendar-date="filterView === 'Week' ? dateTimeRange : currentDate"
          :filter-view="filterView"
          :filters="['Day']"
          class="mt-2"
          @selectDate="setCalendarDate"
          @selectDateRange="setCalendarDateRange"
          @setFilter="setFilter"
          @applyFilter="applyDateFilter"
        ></CalendarActionsWrapper>
        <section class="main-content rounded-md -mt-5">
          <template v-if="isLoading">
            <Loader class="mt-20 mb-40 h-64 pt-12 pb-40" :content="true" />
          </template>
          <div v-else>
            <UiCalendar
              v-if="filterView === 'Month'"
              :key="forceRenderCalendar"
              :calendar-date="currentDate"
            >
              <!-- change the way of passing Dynamic Slot Names -->
              <template v-for="(event, index) in attendance" v-slot:[`day-${getDay(event.date)}`]>
                <div v-if="event.hasOwnProperty.call(event, 'isAction')" :key="index">
                  <div
                    v-if="!event.isAction"
                    class="time-schedule flex justify-center items-center gap-1 text-xs status mb-1 cursor-pointer"
                    @click="singleRecord(event)"
                  >
                    <p
                      class="dot dot_size h-2 w-2 rounded-full"
                      :style="{ backgroundColor: event.checkedStatus.color }"
                    ></p>
                    <span
                      class="text-white text-xs inline-block rounded-sm whitespace-no-wrap text_size presentDate"
                      :style="{
                        backgroundColor: event.checkedStatus.presentColor,
                      }"
                    >
                      {{ event.checked_time }}
                    </span>
                  </div>
                  <div v-if="event.isAction" class="time-schedule viewMore">
                    <p
                      class="font-roboto font-medium text-primary-grey-light viewMoreText cursor-pointer"
                      @click="clickDay(event.date)"
                    >
                      {{ $t(`schedule.View More`) + ' ...' }}
                    </p>
                  </div>
                </div>
              </template>
            </UiCalendar>
            <WeeklyView
              v-else-if="filterView === 'Week'"
              :key="`section-student ${forceRenderCalendar}`"
              :theads="dayRange"
              :table-time="[timeRange[0] || INITIAL_START_TIME, timeRange[1] || INITIAL_END_TIME]"
              :events="events"
              :hide-overlap="true"
              :hide-actions="true"
              @viewEvent="viewEvent"
            ></WeeklyView>
            <SectionAttendanceDailyView
              v-else-if="filterView === 'Day'"
              :list="attendance"
              :current-date="currentDate"
              user-name="Student Name"
              :filters-data="filtersData"
              :table-headers="tableHeaders"
              @update="updateDailyListView"
            >
              <template v-slot:notFound>
                <p v-i18n="attendanceTranslation" class="text-center py-5 text-4xl text-action-bg">
                  <NoRecordFound />
                </p>
              </template>
            </SectionAttendanceDailyView>
          </div>
          <Pagination
            v-if="dayViewCount > GENERAL_CONSTANTS.RECORD_LIMIT && filterView === 'Day'"
            v-show="!isLoading"
            :key="`update-pagination-${forceRender}`"
            class="mx-1"
            :record-limit="filteredRecordLimit"
            :max-range="paginationCounts(dayViewCount, filteredRecordLimit)"
            @filterRecord="loadDayRecord"
          />
        </section>
      </div>
      <UiModalContainer
        footer-classes="flex justify-end"
        :modal-show="dayModal"
        :modal-width="40"
        @handleClick="handleClick"
      >
        <template v-slot:header>
          {{ $t('attendanceTranslation.Check In/Out Details') }}
        </template>
        <template v-slot>
          <div v-for="event in detailsEventsArr" :key="event.id" class="px-5 mb-8">
            <div class="flex flex-col gap-5 sm:flex-row mt-6">
              <span class="text-2xl text-label-text font-bold">
                {{
                  event.checked_type === 'checked_in'
                    ? $t('rightBar.RB_CHECKED1')
                    : $t('rightBar.RB_CHECKED2')
                }}
                : {{ event.comment }}
              </span>
              <span class="subjectColor"></span>
            </div>
            <div class="flex justify-between">
              <div class="py-3 flex flex-1 items-center">
                <icon icon="calender" color="primary-green" height="21" width="18" />
                <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                  {{ event.date || event.day }}
                </span>
              </div>
              <div class="py-3 flex flex-1 clock-icon items-center">
                <icon icon="clock" color="primary-green" height="21" width="18" />
                <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                  {{ event.checked_time }}
                </span>
              </div>
            </div>
            <div class="flex justify-between">
              <div class="py-3 flex flex-1">
                <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                  <span v-i18n="attendanceTranslation">Marked By</span>
                  :

                  <span class="md:px-3 text-primary-grey-light font-normal">
                    {{ event.first_name }} {{ event.last_name }}
                  </span>
                </div>
              </div>
              <div class="py-3 flex flex-1">
                <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                  <span v-i18n="attendanceTranslation">Total Count</span>
                  :

                  <span class="md:px-3 text-primary-grey-light font-normal">
                    {{ event.checked_count }}
                  </span>
                </div>
              </div>
            </div>
            <div class="flex justify-between">
              <div class="py-3 flex flex-1">
                <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                  <span v-i18n="attendanceTranslation">First Name</span>
                  :

                  <span class="md:px-3 text-primary-grey-light font-normal">
                    {{ event.first_name }}
                  </span>
                </div>
              </div>
              <div class="py-3 flex flex-1">
                <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                  <span v-i18n="attendanceTranslation">Last Name</span>
                  :

                  <span class="md:px-3 text-primary-grey-light font-normal">
                    {{ event.last_name }}
                  </span>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </template>
      </UiModalContainer>
      <WeeklyModal
        footer-classes="flex justify-end"
        :modal-show="routinesDetailModal"
        :modal-width="40"
        @handleClick="handleRoutinesDetailClick"
      >
        <template v-slot:header>
          {{ $t('attendanceTranslation.Student Attendance Details') }}
        </template>
        <template v-slot>
          <div v-for="sch in detailsEventsArr" :key="sch.id" class="px-3 md:px-5 mb-8">
            <div class="flex flex-col gap-5 sm:flex-row mt-6">
              <span class="text-2xl text-label-text font-bold">
                {{
                  sch.title === 'checked_in'
                    ? $t('rightBar.RB_SCH_CHECKED1')
                    : $t('rightBar.RB_SCH_CHECKED2')
                }}
              </span>
              <span class="subjectColor"></span>
            </div>
            <div class="flex flex-1 justify-between">
              <div class="py-3 flex flex-1 items-center">
                <icon icon="calender" color="primary-green" height="21" width="18" />
                <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                  {{ sch.date || sch.day }}
                </span>
              </div>
              <div class="py-3 flex flex-1 clock-icon items-center">
                <icon icon="clock" color="primary-green" height="21" width="18" />
                <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                  {{ dateTimeFormat(sch.startTime) }}
                </span>
              </div>
            </div>
            <div class="flex justify-between">
              <div class="py-3 flex flex-1">
                <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                  <span v-i18n="attendanceTranslation">Marked By</span>
                  :

                  <span class="md:px-3 text-primary-grey-light font-normal">
                    {{ sch.checked_by_fname }} {{ sch.checked_by_lname }}
                  </span>
                </div>
              </div>
              <div class="py-3 flex flex-1">
                <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                  <span v-i18n="attendanceTranslation">Total Count</span>
                  :

                  <span class="md:px-3 text-primary-grey-light font-normal">
                    {{ sch.check_count }}
                  </span>
                </div>
              </div>
            </div>
            <div class="flex justify-between">
              <div class="py-3 flex flex-1">
                <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                  <span v-i18n="attendanceTranslation">First Name</span>
                  :

                  <span class="md:px-3 text-primary-grey-light font-normal">
                    {{ sch.checked_by_fname }}
                  </span>
                </div>
              </div>
              <div class="py-3 flex flex-1">
                <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                  <span v-i18n="attendanceTranslation">Last Name</span>
                  :

                  <span class="md:px-3 text-primary-grey-light font-normal">
                    {{ sch.checked_by_lname }}
                  </span>
                </div>
              </div>
            </div>

            <hr />
          </div>
        </template>
      </WeeklyModal>
    </div>
    <MarkAttendanceModal
      v-if="isMarkAttendance"
      :modal="isMarkAttendance"
      @toggle="toggleAttendanceModal"
    />
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import Loader from '@components/BaseComponent/Loader.vue'
import MarkAttendanceModal from '@/src/router/views/attendance/section-attendance/student/MarkStudentSectionAttendanceModal.vue'
import icon from '@components/icons/icon.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import CalendarActionsWrapper from '@components/Calendar/CalendarActionsWrapper.vue'
import UiCalendar from '@components/Calendar/index.vue'
import { formatTimeOnly } from '@src/utils/moment.util.js'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { formatDateString } from '@utils/format-date'
import timeMixin from '@src/mixins/timeMixin'
import WeeklyView from '@src/router/views/attendance/CheckInOutWeekly.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import NoRecordFound from '@src/components/BaseComponent/NoRecordFound.vue'
/* UTILS */
import { getTimeRange } from '@utils/timeTableUtils'
import {
  getStudentAttendanceWeek,
  getSubjectEvent,
} from '@src/router/views/attendance/CheckInOutUtils.js'
import { convertUTCTimeIntoLocalTime } from '@/src/utils/generalUtil'
import {
  INITIAL_START_TIME,
  INITIAL_END_TIME,
} from '@/src/constants/attandance/attandance-constant'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import { getStartTimeOfTheDay, getEndTimeOfTheDay } from '@utils/moment.util'
import sectionAttendanceData from '@src/router/views/attendance/section-attendance/student/sectionAttendanceData.json'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import fileMixin from '@src/mixins/file-mixins'
import { objectDeepCopy } from '@utils/generalUtil'
import SectionAttendanceFilters from '@src/router/views/attendance/section-attendance/student/SectionAttendanceFilters.vue'
import SectionAttendanceDailyView from '@/src/router/views/attendance/section-attendance/student/SectionAttendanceDayView.vue'
import WeeklyModal from '@components/UiElements/UiModalContainer.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'

export default {
  name: 'StudentAttendance',
  components: {
    CalendarActionsWrapper,
    UiCalendar,
    UiModalContainer,
    Pagination,
    SectionAttendanceFilters,
    Loader,
    WeeklyView,
    MarkAttendanceModal,
    NoRecordFound,
    SectionAttendanceDailyView,
    WeeklyModal,
  },
  mixins: [timeMixin, fileMixin, GeneralMixin],
  data() {
    return {
      isEmpty,
      GENERAL_CONSTANTS,
      filterId: null,
      forceRender: 0,
      filterName: null,
      attendanceIn: null,
      attendanceOut: null,
      isLoading: false,
      defaultSelectedColumns: sectionAttendanceData.defaultSelectedColumns,
      tableHeaders: sectionAttendanceData.tableHeaders,
      forceRenderCalendar: 1,
      filterView: 'Day',
      title: 'title',
      attendanceTranslation: 'attendanceTranslation',
      currentDate: new Date(),
      dateTimeRange: null,
      isMobileDevices: window.matchMedia('(max-width: 1023px)').matches,
      dayRange: ['monday', 'friday'],
      timeRange: ['0:00 am', '0:00 am'],
      tempDate: {},
      routinesDetailModal: false,
      filteredRecordLimit: GENERAL_CONSTANTS.RECORD_LIMIT,
      filtersData: {},
      dayViewCount: 0,
      attendance: [],
      respDataObjDump: [],
      events: [],
      studentlist: [],
      dayModal: false,
      detailsEventsArr: [],
      student_id: null,
      INITIAL_START_TIME,
      INITIAL_END_TIME,
      isMarkAttendance: false,
    }
  },
  page: {
    title: 'Attendance | CheckInOut',
    meta: [
      {
        name: 'description',
        content: 'Attendance on the basis of Check In and check out of each subject class ',
      },
    ],
  },
  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope?.id,
      currentClassScope: (state) => state.layout.currentClassScope?.id,
      studentListByClass: (state) => state.student.currentStudentList,
      studentCheckInModal: (state) => state.layout.studentCheckInModal,
      studentCheckOutModal: (state) => state.layout.studentCheckOutModal,
      BulkCheckSubmit: (state) => state.layout.BulkCheckSubmit,
      BulkCheckOutModal: (state) => state.layout.BulkCheckOutModal,
    }),
    ...mapGetters('layout', ['campusTimeZone']),
    dateTimeFormat() {
      return (datetime) => formatTimeOnly(datetime)
    },
  },
  watch: {
    BulkCheckOutModal: {
      deep: true,
      immediate: true,
      handler() {
        if (this.BulkCheckOutModal) {
          this.toggleAttendanceModal()
          this.setBulkCheckOutModal()
          this.mobileRightBar()
        }
      },
    },
    currentSectionScope: {
      deep: true,
      handler() {
        this.applyDateFilter()
      },
    },
    currentClassScope: {
      deep: true,
      handler(value) {
        if (value) this.applyDateFilter()
      },
    },
  },

  created() {
    this.mobileRightBar()
    this.currentDate = new Date()
    const stat = {}
    this.rightBarStats(stat)
  },
  mounted() {
    this.applyDateFilter()
  },
  methods: {
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.applyDateFilter()
    },
    formatTimeOnly,
    toggleAttendanceModal(payload) {
      this.isMarkAttendance = !this.isMarkAttendance
      if (payload) this.applyDateFilter()
    },
    updateDailyListView() {
      this.forceRender++
      const data = { skip: GENERAL_CONSTANTS.RECORD_SKIP, limit: GENERAL_CONSTANTS.RECORD_LIMIT }
      this.loadDayRecord(data)
    },
    /**
     * Mobile Right Bar
     * @description Closes right bar in mobile view when an action is performed
     */
    mobileRightBar() {
      if (this.isMobileDevices) this.$store.commit('layout/SET_TAB_RIGHT_BAR', false)
    },

    /*
     * Set right bar static data and update it after server response
     * Get stat as param which contain stats get from server
     */
    rightBarStats(stat, status) {
      const rightBarContent = {
        header: {
          title: 'Attendance',
          buttons: [
            {
              title: 'MARK_SECTION_STUDENT_ATTENDANCE',
              classes: ['primary-button-right'],
              action: { name: 'layout/setBulkCheckOutModal', payload: {} },
              permissions: { roles: ['super_admin', 'campus_admin', 'section_teacher'] },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'TOTAL_STUDENTS',
                value: stat?.total_students || GENERAL_CONSTANTS.EMPTY_RECORD,
                roles: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                  TENANT_ROLES.CAMPUS_ADMIN,
                ],
              },
              {
                text: 'TOTAL_SECTION_ATTENDANCES',
                value: stat?.section_attendances_count || GENERAL_CONSTANTS.EMPTY_RECORD,
              },
              {
                text: 'PRESENT_STUDENT',
                value: stat?.present_count || GENERAL_CONSTANTS.EMPTY_RECORD,
                roles: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                  TENANT_ROLES.CAMPUS_ADMIN,
                ],
              },
              {
                text: 'ABSENT_STUDENT',
                value: stat?.absent_count || GENERAL_CONSTANTS.EMPTY_RECORD,
                roles: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                  TENANT_ROLES.CAMPUS_ADMIN,
                ],
              },
              {
                text: 'TOTAL_PRESENTS',
                value: stat?.present_count || GENERAL_CONSTANTS.EMPTY_RECORD,
                roles: [TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN],
              },
              {
                text: 'TOTAL_ABSENTS',
                value: stat?.absent_count || GENERAL_CONSTANTS.EMPTY_RECORD,
                roles: [TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN],
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    setCalendarDate(dt) {
      this.currentDate = dt
    },
    clickDay(date) {
      this.detailsEventsArr = this.attendance.filter((single) => single.date === date)
      this.dayModal = true
    },
    /*
     * Open single record model to show record details
     */
    singleRecord(record) {
      this.detailsEventsArr = [record]
      this.dayModal = true
    },
    handleClick(status) {
      switch (status) {
        case 'close':
          this.dayModal = false
          break
        case 'cancel':
          this.dayModal = false
          break
      }
    },

    setCalendarDateRange(dtr) {
      this.tempDate.dateTimeRange = this.dateTimeRange
      this.dateTimeRange = dtr

      // function use For set Range for Weekly call
      this.initializeFilterForWeek(this.dateTimeRange.startDate, this.dateTimeRange.endDate)

      this.forceRenderCalendar++
    },
    /*
     * Load check-in-out weekly  data
     */
    initializeFilterForWeek(startingDate, endingDate = null) {
      const startDate = new Date(startingDate)
      const endDate = new Date(startingDate)

      // Setting this to only get range for 7 days apart
      endDate.setDate(endDate.getDate() + 6)

      const startDayOfWeek = formatDateString(startDate, 'EEEE').toLowerCase()
      const endDayOfWeek = formatDateString(endDate, 'EEEE').toLowerCase()

      this.dateTimeRange = {
        startDate: startDate,
        endDate: endDate,
      }

      this.tempDate.dayRange = this.dayRange

      this.dayRange.splice(0, 1, startDayOfWeek)
      this.dayRange.splice(1, 1, endDayOfWeek)
    },

    setFilter(filterView) {
      this.attendance = []
      this.filterView = filterView

      if (filterView === 'Week') {
        this.initializeFilterForWeek(this.currentDate)
      }

      this.applyDateFilter()
      this.forceRenderCalendar++
    },
    getDay(date) {
      return new Date(date).getDate()
    },

    getTimeOnly(time) {
      const timeObj = time.split(' ')

      return timeObj[0]
    },
    resetFilter() {
      this.student_id = null
      this.applyDateFilter()
    },
    /*
     * filter data on monthly , weekly and daily view
     */
    applyDateFilter() {
      if (!this.currentClassScope) return

      let data = {}
      if (this.student_id) {
        data.user_id = this.student_id
      }
      data.user_type = 'section_student' // filtering data for the section student
      data = this.filtersData ? { ...data, ...{ $where: this.filtersData?.$where } } : data
      switch (this.filterView) {
        case 'Month':
          data.month_year = formatDateString(this.currentDate)
          this.loadMonthsRecord(data)
          break
        case 'Week':
          data.from_date = formatDateString(this.dateTimeRange.startDate)
          data.to_date = formatDateString(this.dateTimeRange.endDate)

          this.loadWeekRecord(data)
          break
        case 'Day':
          data.skip = 0
          data.limit = this.filteredRecordLimit
          this.loadDayRecord(data)
      }
    },
    /*
     * Get resp as Param contain schedule object
     * Transform it into a format use for monthly view
     */
    transformRecordsForMonth(resp) {
      const records = []
      let newRecord = {}
      Object.keys(resp).forEach((date) => {
        let key = 0
        for (let i = 0; i < resp[date].length; i++) {
          const record = resp[date][i]
          if (i === 0) {
            newRecord = {
              id: record.bulk_check_id,
              date: record.checked_at.split('T')[0],
              checked_type: record.checked_type,
              checked_count: record.check_count,
              last_name: record.checked_by_lname,
              first_name: record.checked_by_fname,
              checked_time: convertUTCTimeIntoLocalTime(record.checked_at.split('T')[1], 'hh:mm A'),
              name: record.checked_by_fname,
              comment: record?.comment,
              checkedStatus: {
                color: record.checked_type === 'checked_in' ? '#267C26' : '#921A1D',
                presentColor: '#267C26',
              },
              isAction: false,
              time: record.checked_at,
            }
            records.push(newRecord)
          } else if (i > 0) {
            if (resp[date][i].bulk_check_id !== resp[date][i - 1].bulk_check_id) {
              key++
              newRecord = {
                id: record.bulk_check_id,
                date: record.checked_at.split('T')[0],
                checked_type: record.checked_type,
                checked_count: record.check_count,
                last_name: record.checked_by_lname,
                first_name: record.checked_by_fname,
                comment: record?.comment,
                checked_time: convertUTCTimeIntoLocalTime(
                  record.checked_at.split('T')[1],
                  'hh:mm A',
                ),
                checkedStatus: {
                  color: record.checked_type === 'checked_in' ? '#267C26' : '#921A1D',
                  presentColor: '#267C26',
                },
                time: record.checked_at,
              }
              if (key < 3) {
                newRecord.isAction = false
              } else if (key === 3) {
                newRecord.isAction = true
              }
              records.push(newRecord)
            }
          }
        }
      })
      this.attendance = records
    },
    /*
     * Get resp as Param contain schedule object
     * Transform it into a format use for daily view
     */
    loadMonthsRecord(data = {}) {
      this.isLoading = true
      this.loadSectionAttendance(data)
        .then((resp) => {
          this.transformRecordsForMonth(resp.records)
          this.rightBarStats(resp.meta)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /*
     * Load data on day base
     * Transform data it into a format use for daily view
     */
    loadDayRecord(range = {}) {
      let paginationPayload = paginationRangeHandler(range)
      let date = this.currentDate
      let payload = {
        ...paginationPayload,
        ...{
          $where: {
            ...range?.$where,
            marked_at: {
              [FILTER_KEYS.GREATER_THAN_EQUAL]: `${getStartTimeOfTheDay(date)}${
                this.campusTimeZone
              }`,
              [FILTER_KEYS.LESS_THAN_EQUAL]: `${getEndTimeOfTheDay(date)}${this.campusTimeZone}`,
            },
          },
        },
      }
      this.isLoading = true
      this.loadSectionAttendance(payload)
        .then((resp) => {
          this.attendance = resp?.records
          this.dayViewCount = resp?.meta?.total_records
          this.rightBarStats(resp?.meta)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /*
     * Load data on weekly base
     * Transform data it into a format use for weekly view
     */
    loadWeekRecord(data = {}) {
      // Weekly call function
      let attendances = null
      let timeRange = null
      this.isLoading = true
      this.loadSectionAttendance(data)
        .then((resp) => {
          this.rightBarStats(resp.meta)

          this.respDataObjDump = resp.records
          // format the Data before sending to a weeklyView component
          attendances = getStudentAttendanceWeek(resp.records)

          // Here we send the For data to A function to set
          timeRange = getTimeRange(attendances)
          this.timeRange.splice(0, 1, timeRange[0])
          this.timeRange.splice(1, 1, timeRange[1])

          this.events = attendances
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    handleRoutinesDetailClick(eventType) {
      if (eventType === 'close') {
        this.routinesDetailModal = false
      }
    },

    viewEvent(eventId) {
      let detailsEventsArr = []
      const event = this.events.find((event) => event.id === eventId)
      if (event) {
        detailsEventsArr.push(event)
        if (event?.isOverlapped) {
          const { overlappedIds } = event || {}
          let allOverlappedEvents = null
          const attendances = []
          // format Data
          Object.keys(this.respDataObjDump).forEach((date) => {
            this.respDataObjDump[date].forEach((record) => {
              let date = record.date + 'T' + record.checked_at
              date = new Date(date)
              let endTime = date.setMinutes(date.getMinutes() + 30)
              endTime = new Date(endTime)
              const endTimeFormat =
                endTime.getHours().toString().padStart(2, '0') +
                ':' +
                endTime.getMinutes().toString().padStart(2, '0') +
                ':' +
                endTime.getSeconds().toString().padStart(2, '0')
              const event = getSubjectEvent(record, endTimeFormat)
              attendances.push(event)
            })
          })

          // Find overlap events array and pass to modal
          allOverlappedEvents = overlappedIds.map((id) => {
            return attendances.find((obj) => obj.id === id)
          })

          detailsEventsArr = [...detailsEventsArr, ...allOverlappedEvents]
        }
        this.detailsEventsArr = detailsEventsArr
        this.routinesDetailModal = true
      }
    },

    closeStudentCheckModal() {
      if (this.studentCheckInModal) {
        this.$store.dispatch('layout/setStudentCheckInModal')
      } else if (this.studentCheckOutModal) {
        this.$store.dispatch('layout/setStudentCheckOutModal')
      }
    },

    ...mapActions('student', ['loadSectionAttendance']),

    ...mapActions('layout', ['setRightbarContent', 'setBulkCheckInModal', 'setBulkCheckOutModal']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 31%;
}

.present {
  color: #267c26;
  background-color: #e9f2e9;
}

.delay {
  color: #ffc201;
  background-color: #fff9e6;
}

.absent {
  color: #ea3535;
  background-color: #fce1e1;
}

.halfday {
  color: #ff9421;
  background-color: #fff6ed;
}

.holiday {
  color: #357adb;
  background-color: #eaf1ff;
}
</style>
