import DATE_TIME from '@src/constants/date-time-constants.js'
import { objectDeepCopy } from '@src/utils/generalUtil.js'
import {
  timeIsbefore,
  addTimeInCurrentTime,
  formatTimeOnly,
  timeIsAfter,
  formatDate,
} from '@src/utils/moment.util.js'

export const getSubjectEvent = (payload) => {
  // getting the data from add routine modal
  const event = {
    id: payload.id,
    isOverlapped: false,
    title: payload.subject.title,
    subject: {
      title: payload.subject.title,
      sectionClassSubjectId: payload.subject.class_id,
      subjectId: payload.subject.id,
      color: payload.subject.color,
    },
    section: payload.section.title,
    section_id: payload.section.id,
    teacher: `${payload?.teacher?.first_name || ''} ${payload?.teacher?.last_name || ''}`,
    teacher_id: payload?.teacher?.id,
    details: true,
    day: payload.day.toLowerCase(),
    // date: subject.date,
    room: payload?.room?.title || payload?.room?.number,
    room_id: payload?.room?.id,
    startTime: payload.started_at_time,
    endTime: payload.ended_at_time,
  }

  return event
}

const timeComparison = (timeOne, timeTwo) => {
  const timeOneSplit = timeOne
  const timeTwoSplit = timeTwo
  if (timeOneSplit[0] < timeTwoSplit[0]) {
    return 1
  } else if (timeOneSplit[0] > timeTwoSplit[0]) {
    return 2
  } else {
    if (timeOneSplit[1] < timeTwoSplit[1]) {
      return 1
    } else if (timeOneSplit[1] > timeTwoSplit[1]) {
      return 2
    } else {
      if (timeOneSplit[2] < timeTwoSplit[2]) {
        return 1
      } else if (timeOneSplit[2] > timeTwoSplit[2]) {
        return 2
      } else {
        return 3
      }
    }
  }
}

const timeCollapsed = (current, record) => {
  const currentRecordStartTime = current.started_at.split('T')[1]
  const currentRecordEndTime = current.ended_at.split('T')[1]
  const recordStartTime = record.started_at.split('T')[1]
  const recordEndTime = record.ended_at.split('T')[1]
  if (current.day !== record.day) {
    return false
  } else if (
    timeComparison(currentRecordStartTime, recordStartTime) === 1 &&
    timeComparison(currentRecordEndTime, recordStartTime) === 2 &&
    timeComparison(currentRecordEndTime, recordEndTime) === 1
  ) {
    return true
  } else if (
    timeComparison(currentRecordStartTime, recordStartTime) === 2 &&
    timeComparison(currentRecordStartTime, recordEndTime) === 1
  ) {
    return true
  } else if (
    timeComparison(currentRecordStartTime, recordStartTime) === 3 ||
    timeComparison(currentRecordEndTime, recordEndTime) === 3
  ) {
    return true
  } else return false
}

const findCollappsingForRecords = (current, all) => {
  return all
    .filter((record) => {
      if (record.id === current.id) {
        return false
      } else {
        return timeCollapsed(current, record)
      }
    })
    .map((r) => r.id)
}
/* getting the detailed date and time data from add routine modal and dsiplaying it according to the start and end time */
export const getSubjectEventUtc = (payload) => {
  const event = {
    id: payload.id,
    isOverlapped: payload.overlappedIds?.length > 0,
    overlappedIds: payload.overlappedIds || [],
    title: payload.subject.title,
    subject: {
      title: payload.subject.title,
      sectionClassSubjectId: payload.subject.class_id,
      subjectId: payload.subject.id,
      color: payload.subject.color,
    },
    attendanceMeta: {
      present: payload.attendance_meta.present_rate,
      absent: payload.attendance_meta.absent_rate,
    },
    section: payload.section.title, // changed class title to section title
    section_id: payload.section.id,
    lesson: {
      title: payload.lesson?.title || '',
      type: payload.lesson?.type || '',
    },
    lesson_id: payload.lesson_id,
    teacher: `${payload?.teacher?.first_name || ''} ${payload?.teacher?.last_name || ''}`,
    teacher_id: payload?.teacher?.id,
    details: true,
    day: payload.day.toLowerCase(),
    date: formatDate(payload.started_at),
    endDate: formatDate(payload.ended_at),
    room: payload?.room?.title || payload?.room?.number,
    room_id: payload?.room?.id,
    startTime: formatDate(payload.started_at, 'HH:mm:ss'),
    endTime: formatDate(payload.ended_at, 'HH:mm:ss'),
  }
  return event
}

export const getEventsWithNonOverlap = (records) => {
  const allEvents = records.reduce((acc, record) => {
    const event = {
      ...record,
      parentId: null,
      overlappedIds: [],
    }
    event.overlappedIds = findCollappsingForRecords(record, records)

    const isExist = []

    event.overlappedIds.forEach((id) => {
      if (acc[id]) {
        isExist.push(id)
      }
    })

    // acc = [...acc, event];

    if (isExist.length === 0) {
      acc[event.id] = { ...event }
    } else {
      if (isExist.length !== 1) {
        const allOverlapped = isExist.reduce((ac, val) => {
          acc[val].parentId = event.id
          ac = [...ac, ...acc[val].overlappedIds]

          return ac
        }, [])

        event.overlappedIds = [...event.overlappedIds, ...allOverlapped]
      } else {
        if (!acc[isExist[0]].parentId) {
          acc[isExist[0]].overlappedIds = [...acc[isExist[0]].overlappedIds, ...event.overlappedIds]

          event.parentId = isExist[0]
        } else {
          acc[acc[isExist[0]].parentId].overlappedIds = [
            ...acc[acc[isExist[0]].parentId].overlappedIds,
            ...event.overlappedIds,
          ]

          event.parentId = acc[isExist[0]].parentId
        }
      }

      acc[event.id] = { ...event }
    }

    return acc
  }, {})

  return Object.values(allEvents)
    .filter((e) => !e.parentId)
    .map((e) => {
      return {
        ...e,
        overlappedIds: e.overlappedIds
          .filter((id) => id !== e.id)
          .reduce((ac, val) => {
            if (!ac.includes(val)) {
              ac.push(val)
            }

            return ac
          }, []),
      }
    })
    .map((record) => {
      return getSubjectEventUtc(record)
    })
}

/**
 * Dynamically create start and end of weekly view time range
 * @param {Array} -array of events
 * @return {Array} - array of start and end time of day
 */

export const getTimeRange = (eventObjectsArr = []) => {
  if (eventObjectsArr.length === 0) return 0
  const events = eventObjectsArr.map((record) => {
    return {
      startTime: record.startTime,
      endTime: record.endTime,
    }
  })

  const initialEvent = events[0]

  events.forEach((event) => {
    if (timeIsbefore(event.startTime, initialEvent.startTime))
      initialEvent.startTime = event.startTime
    if (timeIsAfter(event.endTime, initialEvent.endTime)) initialEvent.endTime = event.endTime
  })

  let eventEndTime = initialEvent.endTime
  events.forEach((event) => {
    if (timeIsAfter(event.startTime, eventEndTime)) {
      eventEndTime = event.startTime
    }
  })

  var startTime = formatTimeOnly(
    initialEvent.startTime,
    DATE_TIME.time12H,
    DATE_TIME.TIME_12_RESET_MINUTES,
  )
  var endTime = formatTimeOnly(eventEndTime, DATE_TIME.time12H, DATE_TIME.TIME_12_RESET_MINUTES)

  return [startTime, endTime]
}

/**
 * Get Weekly view  time range and create time array
 * @param {Array} tableRange
 * @returns  {Array} Array of times
 */

export function tableSide(tableRange) {
  let range = null
  let endTime = null
  let startTime = null
  let isCompleted = false
  const tablerange = []

  range = objectDeepCopy(tableRange)
  startTime = range[0]
  endTime = range[1]

  do {
    if (timeIsbefore(startTime, endTime)) {
      let time = ''

      tablerange.push(startTime)

      time = addTimeInCurrentTime(startTime, 'hours', 1, DATE_TIME.time24H)

      startTime = formatTimeOnly(time, DATE_TIME.time12H, DATE_TIME.time12H)
    } else {
      let time = ''

      tablerange.push(startTime)

      time = addTimeInCurrentTime(startTime, 'hours', 1, DATE_TIME.time24H)

      startTime = startTime = formatTimeOnly(time, DATE_TIME.time12H, DATE_TIME.time12H)

      isCompleted = true
    }
  } while (!isCompleted)

  return tablerange
}

export function getEventColor(color = '', colorOpacity = 600, defaultColor = '') {
  return /^#([0-9A-F]{3}){1,2}$/i.test(color)
    ? defaultColor || color
    : `var(--${color}-${colorOpacity})`
}
